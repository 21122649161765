const subjects = [
  'Following up with some content',
  "I think you'll appreciate this",
  'Something new for you',
  'You have to check this out',
  'An exclusive sneak peek for you',
  "You're in for a treat",
  'Ready to be impressed?',
  'Ready to be amazed?',
  'Important content update inside',
  'Discover something exciting',
];

module.exports = subjects;
