import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { getUser } from '../api/profile';
import { setProfile } from '../state/userSlice';
import { getMedia } from '../api/media';
import { setMediaItems } from '../state/mediaSlice';

const LOGIN = 'login';
const UNAGREED = 'unagreed';
const UNVERIFIED = 'unverified';
const SETUP_REQUIRED = 'setup_required';
const VERIFIED = 'verified';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const mediaItems = useSelector((state) => state.media.items);
  const [canNavigate, setCanNavigate] = useState(false);
  const [route, setRoute] = useState('');

  const reloadProfile = async () => {
    console.log('reloading profile');
    dispatch(setLoading(true));
    try {
      const user = await getUser();
      dispatch(setProfile(user.data));
      const media = await getMedia();
      dispatch(setMediaItems(media.data));
      setCanNavigate(true);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    reloadProfile();
  }, [location]);

  useEffect(() => {
    setRoute(selectProtectedRoute());
  }, [profile, mediaItems]);

  useEffect(() => {
    window.addEventListener('storage', reloadProfile);

    return () => {
      window.removeEventListener('storage', reloadProfile);
    };
  }, []);

  const selectProtectedRoute = () => {
    if (!localStorage.getItem('auth_token')) {
      return LOGIN;
    }
    const token = localStorage.getItem('auth_token');
    const user = decodeToken(token);
    if (!user.agreed_at) {
      return UNAGREED;
    }
    if (!user.verified) {
      return UNVERIFIED;
    }
    if (
      canNavigate &&
      (mediaItems?.filter((item) => item.status == 'LIVE').length < 5 ||
        !profile?.only_fans ||
        !profile?.media_id)
    ) {
      return SETUP_REQUIRED;
    }
    return VERIFIED;
  };

  console.log(route);

  if (route === LOGIN) {
    return <Navigate to="/dashboard/login" state={{ from: location }} />;
  } else if (route == UNAGREED) {
    return <Navigate to="/dashboard/agreement" state={{ from: location }} />;
  } else if (route === UNVERIFIED) {
    return <Navigate to="/dashboard/unverified" state={{ from: location }} />;
  } else if (route == SETUP_REQUIRED) {
    return <Navigate to="/dashboard/setup" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
