const content = [
  {
    title: 'Join me',
    caption: "I offer exclusive content you won't find anywhere else!",
  },
  {
    title: 'Unlock it all',
    caption: "You'll receive the full set when you subscribe today.",
  },
  {
    title: 'Want more?',
    caption: 'Come check out my OnlyFans page for all the access!',
  },
  {
    title: "You won't regret it",
    caption: "I promise it'll be worth it ;)",
  },
  {
    title: 'Join my club',
    caption: 'For a limited time only!',
  },
  {
    title: 'Subscribe now',
    caption: 'And get instant access to all my sexy content.',
  },
  {
    title: 'Exclusive access',
    caption: 'To my most intimate moments.',
  },
  {
    title: 'Get addicted',
    caption: 'To my naughty content!',
  },
  {
    title: 'Unlock the naughtiness',
    caption: 'And get access to my private collection.',
  },
  {
    title: 'Experience me',
    caption: 'In ways you never have before.',
  },
  {
    title: 'Be my VIP',
    caption: 'And get exclusive access to my most seductive content.',
  },
  {
    title: 'Join me now',
    caption: 'For daily updates of my hottest content!',
  },
  {
    title: 'Get closer',
    caption: 'To me and my sexy content.',
  },
  {
    title: 'Subscribe for more',
    caption: 'Of my XXX fun!',
  },
  {
    title: "Don't miss out",
    caption: 'On all the naughty things I have to offer.',
  },
  {
    title: 'Sneak peek',
    caption: "Of what you'll get when you subscribe now!",
  },
  {
    title: 'Indulge in me',
    caption: 'And my sinful content.',
  },
  {
    title: 'Discover my secrets',
    caption: 'When you subscribe to my OnlyFans page.',
  },
  {
    title: 'Get naughty with me',
    caption: 'And unlock all my sexy content!',
  },
  {
    title: 'Join me for fun',
    caption: "And exclusive content you won't find anywhere else!",
  },
  {
    title: 'Treat yourself',
    caption: 'To all my naughty content when you subscribe today.',
  },
  {
    title: 'See everything',
    caption: 'When you subscribe to my OnlyFans page!',
  },
  {
    title: 'Join me for a ride',
    caption: 'And let me show you my sexy side!',
  },
  {
    title: 'Unlock the heat',
    caption: 'And get access to my hottest content yet.',
  },
];

module.exports = content;
