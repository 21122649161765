import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../state/uiSlice';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../api/auth';
import Logo from '../assets/icons/logo.svg';
import IDInfo from '../assets/icons/id.jpg';

function Unverified() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading);
  const refreshToken = async () => {
    if (loading) return;
    dispatch(setLoading(true));
    try {
      const response = await getToken();
      const token = response.data.jwt;
      localStorage.setItem('auth_token', token);
      dispatch(setLoading(false));
      navigate('/dashboard/media');
    } catch (e) {
      console.log(e.response);
    }
    dispatch(setLoading(false));
  };

  return (
    <main className="main mb-10 p-4 max-w-2xl mx-auto flex flex-col items-center">
      <img src={Logo} className="w-[48px] mt-20" />
      <h2 className="mt-6 text-[30px] font-semibold text-gray-900">
        Unverified
      </h2>
      <p className="text-gray-700 mt-4">
        Please e-mail a{' '}
        <strong>
          clear photo of you, holding your driver's license next to your face
        </strong>{' '}
        as a single image to{' '}
        <a
          href="mailto:verification@couldbefans.com"
          className="underline text-cbf-primary font-semibold"
        >
          verification@couldbefans.com
        </a>
        .
      </p>
      <img src={IDInfo} className="max-w-sm m-4" />

      <button
        onClick={() => refreshToken()}
        className="button font-semibold px-6 py-3 bg-cbf-primary rounded-lg w-full text-white mt-4"
      >
        Refresh Verification Status
      </button>
      <p className="mt-2 text-sm text-gray-600">
        Why? To protect all creators and users we need to make sure anyone using
        CouldBeFans is at least 18 years old or of the age of majority in their
        place of residence.
      </p>
    </main>
  );
}

export default Unverified;
